

    
        .searchbar{
        /* margin-bottom: 20px; */
        margin-top: auto;
        /* height: 50px; */
        background-color: #353b48;
        border-radius: 30px;
        padding: 10px;
        }
        .search_input{
        color: white;
        border: 0;
        outline: 0;
        background: none;
        width: 200px;
        caret-color:transparent;
        line-height: 30px;
        transition: width 0.4s linear;
        }
    
        .searchbar:hover > .search_input{
        padding: 0 10px;
        width: 250px;
        caret-color:red;
        transition: width 0.4s linear;
        }
    
        .searchbar:hover > .search_icon{
        background: white;
        color: #e74c3c;
        }
    
        .search_icon{
        height: 30px;
        width: 30px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color:white;
        text-decoration:none;
        }