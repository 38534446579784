.card0 {
    box-shadow: 0px 4px 8px 0px #757575;
    border-radius: 20px
}

.card2 {
    margin: 0px 20px
}

/* .logo {
    padding-left: 20px;
    margin-top: 20px;
} */

.image {
    height: 280px;
}




@media screen and (max-width: 991px) {
    .logo {
        margin-left: 0px
    }
    .image {
        width: 200px;
        height: 220px
    }
    .border-line {
        border-right: none
    }
    .card2 {
        border-top: 1px solid #EEEEEE !important;
        margin: 0px 15px
    }
}

    .myicon {
        display: flex;
        margin: auto;
    }